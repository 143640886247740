import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <Container fluid={true} className="bg">
      <Row>
        <Col>
          <Container>
            <Row style={{ fontWeight: "bold", color: "grey" }}>
              <Col xs={12} lg={4}>
                <p>
                  <a className="logo-plain-link" href="/">
                    <img src="/images/logo192.png" alt="logo" height="40px" />
                    &nbsp;<span className="co-name">Sun One Page Limited</span>
                  </a>
                </p>
              </Col>
              <Col className="d-block d-sm-none" xs={12}>
                <p className="top-space">
                  <a className="plain-link" href="/privacy">
                    Privacy
                  </a>
                </p>
              </Col>
              <Col xs={12} sm={9} lg={6}>
                <p className="top-space">
                  &#169; Sun One Page Limited. Registered in England and Wales
                  under registered number 13134844
                </p>
                <p>
                  Powered by Sun One Page Limited (
                  {process.env.REACT_APP_VERSION})
                </p>
              </Col>
              <Col className="d-none d-sm-block">
                <p className="top-space text-center">
                  <a className="plain-link" href="/privacy">
                    Privacy
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
