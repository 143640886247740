import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Privacy from "./components/privacy/Privacy";
import Home from "./components/home/Home";
import Header from "./components/header/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "./components/footer/Footer";
import { CookiesProvider } from "react-cookie";

interface PageHolderProps {
  text: string;
}

const PageHolder: React.FC<PageHolderProps> = ({ text }) => (
  <div>
    <Header />
    <Container>
      <Row>
        <Col>
          <p className="not-ready">Oops, sorry! {text} page is not ready yet</p>
        </Col>
      </Row>
    </Container>
    <Footer />
  </div>
);

const router = createBrowserRouter([
  { path: "/privacy", element: <Privacy /> },
  {
    path: "/contactus",
    element: <PageHolder text="Contact us"></PageHolder>,
  },
  {
    path: "/services/customize",
    element: <PageHolder text="Customization"></PageHolder>,
  },
  {
    path: "/services/si",
    element: <PageHolder text="Solution integration"></PageHolder>,
  },
  {
    path: "/services/workflow-api",
    element: <PageHolder text="Workflow API"></PageHolder>,
  },
  {
    path: "/products/bookkeeper",
    element: <PageHolder text="Bookkeeper"></PageHolder>,
  },
  {
    path: "/products/crm",
    element: <PageHolder text="CRM"></PageHolder>,
  },
  {
    path: "/products/inventory",
    element: <PageHolder text="Inventory"></PageHolder>,
  },
  {
    path: "/products/rota",
    element: <PageHolder text="Rota"></PageHolder>,
  },
  {
    path: "/products/ticketing",
    element: <PageHolder text="Ticketing"></PageHolder>,
  },
  {
    path: "/products/workflow",
    element: <PageHolder text="Workflow"></PageHolder>,
  },
  { path: "/", element: <Home /> },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <RouterProvider router={router} />
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
