import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../modal/Modal";
import Header from "../header/Header";
import Banner from "../banner/Banner";
import "./Home.css";
import ProductCard from "../productCard/ProductCard";
import Footer from "../footer/Footer";
import { useCookies } from "react-cookie";

const Home: React.FC = () => {
  const STR_COOKIES_ACCEPT_TIME: string = "cookies-accept-time";
  const [cookies, setCookies] = useCookies([STR_COOKIES_ACCEPT_TIME]);

  const [isModalOpen, setIsModalOpen] = useState(
    !cookies[STR_COOKIES_ACCEPT_TIME]
  );
  const closeModal = () => {
    const ts = new Date().toISOString();
    setCookies(STR_COOKIES_ACCEPT_TIME, ts.replaceAll(":", ""), {
      path: "/",
      maxAge: 7 * 24 * 60 * 60,
    });
    setIsModalOpen(false);
    toast.success("Cookies accepted", {
      position: "bottom-right",
      pauseOnFocusLoss: false,
      autoClose: 3000,
    });
  };

  return (
    <>
      <div className="container">
        <Header />
        <Banner index={0} setIndex={(idx) => {}} />
        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-4 mt-3">
            <ProductCard
              imgPosition="top"
              imgSrc="/images/01_products.png"
              title="Apps suite for Startups"
              subTitle="Hit the road and running"
              texts={[
                "Starting a business is already hard enough, don't make it harder because you don't have software apps that fit you.",
                "With our suite of apps from bookkeeping to rota management to customer management, you can truly focus all your energy on promoting your business and make it sustainable",
              ]}
              links={[
                { url: "/products/bookkeeper", text: "Bookkeeper" },
                { url: "/products/crm", text: "CRM" },
                { url: "/products/inventory", text: "Inventory" },
                { url: "/products/rota", text: "Rota" },
                { url: "/products/ticketing", text: "Ticketing" },
                { url: "/products/workflow", text: "Workflow" },
              ]}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3">
            <ProductCard
              imgPosition="top"
              imgSrc="/images/02_api_automation.png"
              title="APIs & Automations"
              subTitle="Streamlining your business"
              texts={[
                "Once your set of business procedures are run in, your business could be brought to the next level by streamlining and automating them through our Workflow app.",
                "Not only would your team experiences smoother performance, but they can also evolve these visualized procedures to adapt to ever changing business needs",
              ]}
              links={[
                {
                  url: "/services/workflow-api",
                  text: "Know more about our workflow APIs",
                },
              ]}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3">
            <ProductCard
              imgPosition="top"
              imgSrc="/images/03_customized_solutions.jpg"
              title="Custoimized Solutions"
              subTitle="Really do it your way"
              texts={[
                "While the apps and techs used in your business and your team might be off the shelf, your way to integrate them as a whole is unique in this commercial world. This is where our solution integration service comes in",
                "Or you might have needs that can only be best catered for by customized solutions. Our team are always happy to talk about ways to meet your needs on this aspect too",
              ]}
              links={[
                { url: "/services/si", text: "Solution Integrations" },
                { url: "/services/customize", text: "Customized solutions" },
              ]}
            />
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Our use of cookies"
      >
        <p>Necessary cookies</p>
        <p>
          Necessary cookies enable core functionality such as security, network
          management, and accessibility.
        </p>
        <p>
          You may disable these by changing your browser settings, but this may
          affect how the website functions.
        </p>
        <p>
          For more information please see our{" "}
          <a href="/privacy">Privacy Notice</a>
        </p>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Home;
