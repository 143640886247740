import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./Privacy.css";

const Privacy: React.FC = () => {
  return (
    <>
      <div className="container">
        <Header />
        <div>
          <p className="fs-1">Privacy Notice</p>
          <p>
            Sun One Page Limited (referred to as "we", "our" or "us" in this
            privacy notice) is committed to safeguarding your personal
            information. This is an important notice regarding your privacy and
            the way in which we collect and make use of your personal
            information. This privacy notice sets out the basis on which any
            personal information we collect from you, or that you provide to us,
            will be processed by us. Please read the following carefully to
            understand our views and practices regarding your personal
            information and how we will treat it
          </p>
          <p>
            This website is not intended for children and we do not knowingly
            collect data relating to children. We do not collect any special
            categories of personal information about you (this includes details
            about your race or ethnicity, religious or philosophical beliefs,
            sex life, sexual orientation, political opinions, trade union
            membership, information about your health and genetic and biometric
            data). We do not collect any information about criminal convictions
            and offences either
          </p>
          <p className="fs-2">Information we collect</p>
          <p>
            To provide you with our services and products we may collect and
            process information about your usage data such as details of your
            visit to our website, pages viewed and resources that you accessed.
            Such information includes traffic data, location data, device
            specific information and other communication data
          </p>
          <p>
            For the purpose of applicable privacy legislation, the data
            controller is Sun One Page Limited, 23 Argyle Street, Liverpool,
            United Kingdom L1 5BL. Our company is registered in England and
            Wales under the registration number 13134844
          </p>
          <p>
            You have the right to make a complaint at any time to the
            Information Commissioner's Office (ICO), the UK supervisory
            authority for data protection issues (www.ico.org.uk). However, we
            would appreciate the chance to deal with your concerns before you
            approach the ICO. So for questions in relation to this privacy
            notice or any concerns that this privacy notice has not been
            followed, please contact our Data Protection Officer in the first
            instance at{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p>
            The current version of this privacy notice was last updated on 13th
            June 2022
          </p>
          <p className="fs-2">The data we collect about you</p>
          <p>
            Personal information means any information about an individual from
            which that person can be identified. It does not include data where
            the identity has been removed (anonymized data)
          </p>
          <p>
            We may collect, use, store and transfer different kinds of personal
            information about you. However, we do not and will not sell your
            personal information to third parties. Your personal information can
            belong to one or more of below categories:
          </p>
          <p className="fs-3">Customers</p>
          <ul>
            <li>
              Contact details data including your name, title, contact phone
              number, contact email address, residential address and social
              media personal addresses for retail customers, or your contact
              person name and contact phone number, your director's name and
              contact phone number, your contact person business title for
              corporate customers
            </li>
            <li>
              Contractual data including your customer number, your license
              effective and expiry dates, your state identity number or passport
              number and your signature for retail customers
            </li>
            <li>
              Usage data including information about how you use our website,
              products and services
            </li>
            <li>
              Transaction data including your order numbers, your invoice
              numbers, your receipt numbers, your bank account details, your
              credit card or debit card details, your payment platform details
              like PayPal email address and PayPal account number you used for
              payments to our products and services
            </li>
            <li>
              Profile data including your username and password, your
              preferences receiving newsletter or marketing materials from us
              and your preferences on communication means (for instance email
              address or messaging platform details)
            </li>
            <li>
              Technical data including your IP address, your login data, browser
              type and version, time zone setting and location, browser plug-in
              types and versions, operating system and platform and other
              technology on the devices you use to access this website
            </li>
          </ul>
          <p className="fs-3">Newsletter subscribers</p>
          <ul>
            <li>
              Contact details data including your name and contact email address
              for newsletter subscribers
            </li>
          </ul>
          <p className="fs-3">Suppliers' employees</p>
          <ul>
            <li>
              Your personal details data including your name, email address and
              business telephone number
            </li>
          </ul>
          <p className="fs-3">Miscellaneous</p>
          <ul>
            <li>
              Any personal information you give us during your interactions with
              us
            </li>
            <li>
              Information gathered by cookies used on our website. Our website
              uses cookies to distinguish you from other users of our website.
              This helps us to provide you with a good experience when you
              browse and also allows us to improve the way our website works.
              For detailed information on the cookies we use and the purposes
              for which we use them please refer to our{" "}
              <a href="#cookies">Cookies</a> section below
            </li>
          </ul>
          <p className="fs-2">Use of your information</p>
          <p>
            We will only use your personal information when the law allows us
            to. Generally we do not rely on consent as a legal ground for
            processing your personal information other than in relation to
            sending third party direct marketing communications to you via
            email. You have the right to withdraw consent to marketing at any
            time by email to{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p>
            When we use your personal information, we must have a legal ground
            for doing so. The following are the legal grounds by which we can
            use your personal information:
          </p>
          <ul>
            <li>
              Where you have provided us with your consent for us to do so
            </li>
            <li>
              Where we need to perform the contract we have entered into with
              you or to take steps to enter into a contract with you
            </li>
            <li>
              Where we need to comply with a legal obligation to which we are
              subject
            </li>
            <li>
              Where it is in pursuit of our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests
            </li>
          </ul>
          <p>
            More specifically, we will use your personal information in the
            following circumstances:
          </p>
          <p className="fs-3">Customers</p>
          <ul>
            <li>
              To manage our relationship with you which includes notifying you
              about changes to our product / service terms, policy, service
              hours, server down times, service scopes, we will use your contact
              details data, your contractual data and your profile data. The
              legal grounds are to perform a contract with you or to take steps
              to enter into a contract with you and to comply with legal
              obligations
            </li>
            <li>
              To make suggestions and recommendations to you about products and
              services you may be interested, we will use your contact details
              data, your contractual data, your profile data, your usage data,
              your transaction data and your technical data. The legal grounds
              are to develop our products and services and grow our business, to
              keep our records updated and to study how you use our products and
              services as needed for our legitimate interests
            </li>
            <li>
              To manage payments, fees, charges and to collect money owed to us,
              we will use your contact details data, your contractual data, your
              transaction data and your profile data. The legal grounds are to
              perform a contract with you and to comply with legal obligations
            </li>
          </ul>
          <p className="fs-3">Newsletter subscribers</p>
          <ul>
            <li>
              To send newsletter to you about products and services you may be
              interested, we will use your contact details data including your
              name and email address. The legal ground is the consent you give
              us when you subscribe to our newsletters
            </li>
          </ul>
          <p className="fs-3">Suppliers' employees</p>
          <ul>
            <li>
              To manage our relationship with you which includes facilitating
              business between you and us, we will use your personal details
              data. The legal grounds are to perform a contract with you and to
              comply with legal obligation
            </li>
          </ul>
          <p className="fs-3">Generally</p>
          <ul>
            <li>
              to provide you with information on our services, marketing
              information and insights, we will use your contact details data
              you have provided us with your consent for us to do so
            </li>
            <li>
              to administer our website and for internal operations, including
              troubleshooting, data analysis, testing, research, statistical and
              survey purposes, we will use your usage data and transaction data.
              The legal ground is to better understand how you interact with our
              website and solve any issues it may have for our legitimate
              interests
            </li>
          </ul>
          <p className="fs-2">
            <a id="cookies">Use of cookies</a>
          </p>
          <p>
            This website uses cookies. A cookie is a small text file stored in
            your computer containing text data. We use cookies for certain
            functions to improve the usability of the website. However, enabling
            cookies in your web browser is necessary if you wish your selections
            to be remembered for future visits on the same browser. You can set
            your browser to refuse all or some browser cookies, or to alert you
            when websites set or access cookies. For more information about
            cookies and instructions on how to adjust your browser settings to
            restrict or disable cookies, see the IAB website at{" "}
            <a href="https://www.allaboutcookies.org">
              www.allaboutcookies.org
            </a>
            . If you disable or refuse cookies, please note that some parts of
            this website may become inaccessible or not function properly
          </p>
          <p className="fs-2">Change of purpose</p>
          <p>
            We will only use your personal information for the purposes for
            which we collected it, unless we reasonably consider that we need to
            use it for another reason and that reason is compatible with the
            original purpose. If we need to use your personal information for an
            unrelated purpose, we will notify you and we will explain the legal
            basis which allows us to do so. If you wish to get an explanation as
            to how the processing for the new purpose is compatible with the
            original purpose, please contact us at{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p className="fs-2">Disclosure of your personal information</p>
          <p>
            Generally we do not disclose your personal information to third
            parties. For our website to properly display your personal
            information when you browse, we might need to transit your digitally
            encrypted personal information from our servers to your browser
            securely through our website hosting service provider{" "}
            <a href="https://www.a2hosting.com">A2 Hosting</a>. A2 Hosting is a
            US company complies with the EU-US Privacy Shield Framework and the
            Swiss-U.S. Privacy Shield Framework as set forth by the US
            Department of Commerce regarding the collection, use, and retention
            of personal information from European Union member countries and
            Switzerland. Please refer to the "EU-US Privacy Shield Framework"
            section in A2 Hosting's{" "}
            <a href="https://www.a2hosting.com/about/website-privacy/">
              website privacy
            </a>{" "}
            and the "Privacy Policy" section in their{" "}
            <a href="https://www.a2hosting.com/policies/#Privacy-Policy">
              Policies
            </a>{" "}
            page for complete statement and more details
          </p>
          <p className="fs-2">Retention of your personal information</p>
          <p>
            We will only retain your personal information for as long as
            necessary to fulfill the purposes we collected it for
          </p>
          <p>
            We assess the appropriate retention period for different information
            based on the size, volume, nature and sensitivity of that
            information, the potential risk of harm to you from unauthorised use
            or disclosure of that information, the purposes for which we are
            using that information, applicable legal requirements for holding
            that information, and whether we can achieve those purpose(s)
            through other means
          </p>
          <p className="fs-3">Customers</p>
          <p>
            The period we keep your personal information depends on a number of
            circumstances. For instance, we will keep your personal information
            for as long as your service subscription contract or product
            maintenance are effective. We will keep your personal information
            for an extended period we are legally required after your service
            contract or product maintenance ends (for example to fulfill our
            taxation and reporting responsibilities). For specific timeframes of
            how long we keep your personal information for, please contact us at{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p className="fs-3">Newsletter subscribers</p>
          <p>
            We will keep your contact details data until you unsubscribe from
            us. To unsubscribe from our newsletter, please contact us at{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p className="fs-3">Suppliers' employees</p>
          <p>
            We will keep your personal information for as long as we and you do
            business together or for as long as we have a commercial interest in
            holding your personal information, for example, with a view to doing
            business in the future. For specific timeframes of how long we keep
            your personal data for, please contact us at{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p className="fs-2">Transfers outside the European Economic Area</p>
          <p>
            Data we collect from you may be stored and processed in and
            transferred to countries outside of the European Economic Area
            (EEA). For example, this could occur if our servers are located in a
            country outside the EEA or one of our service providers is situated
            in a country outside the EEA
          </p>
          <p>
            We will only transfer Data outside the EEA where it is compliant
            with data protection legislation and the means of transfer provides
            adequate safeguards in relation to your data, eg by way of data
            transfer agreement, incorporating the current standard contractual
            clauses adopted by the European Commission, or by signing up to the
            EU-US Privacy Shield Framework, in the event that the organisation
            in receipt of the Data is based in the United States of America
          </p>
          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees and suppliers who have a
            business need to know. They will only process your personal data on
            our instructions and they are subject to a duty of confidentiality
          </p>
          <p>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so
          </p>
          <p className="fs-2">Your legal rights</p>
          <p>Under certain circumstances, by law you have the right to:</p>
          <ul>
            <li>
              Request access to your personal information (known as a "Data
              Subject Access Request" or "DSAR" in short). This enables you to
              receive a copy of the personal information we hold about you and
              to check that we are lawfully processing it
            </li>
            <li>
              Request correction of the personal information that we hold about
              you. This enables you to have any incomplete or inaccurate
              information we hold about you corrected
            </li>
            <li>
              Request the erasure of your personal information. This enables you
              to ask us to delete or remove personal information when there is
              no good reason for us continuing to process it. You also have the
              right to ask us to stop processing personal information where we
              are relying on a legitimate interest and there is something about
              your particular situation which makes you want to object to
              processing on this ground
            </li>
            <li>
              Request the restriction of processing of your personal
              information. This enables you to ask us to suspend the processing
              of personal information about you, for example if you want us to
              establish its accuracy or the reason for processing it
            </li>
            <li>
              Request the transfer of your personal information to another party
            </li>
          </ul>
          <p>
            If you want to review, verify, correct or request erasure of your
            personal information, object to the processing of your personal
            data, or request that we transfer a copy of your personal
            information to another party, please contact us at{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p className="fs-2">No fee usually required</p>
          <p>
            Usually you will not have to pay a fee to access your personal
            information (or to exercise any of the other rights). However, we
            may charge a reasonable fee if your request for access is clearly
            unfounded or excessive. Alternatively, we may refuse to comply with
            the request in such circumstances
          </p>
          <p className="fs-2">What we may need from you</p>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access any personal
            information (or to exercise any of your other rights). This is a
            security measure to ensure that personal information is not
            disclosed to another person who has no right to receive it
          </p>
          <p className="fs-2">Right to withdraw consent</p>
          <p>
            In the limited circumstances where you may have provided us with
            your consent to the collection, processing and transfer of your
            personal information for a specific purpose, you have the right to
            withdraw your consent for that specific processing at any time. To
            withdraw your consent, please contact us at{" "}
            <a href="mailto:dpo@sunonepage.com">dpo@sunonepage.com</a>
          </p>
          <p>
            Where we are providing you with marketing information, you can also
            change your marketing preferences by using the unsubscribe button at
            the bottom of our marketing e-mails sent to you
          </p>
          <p>
            Once we have received notification that you have withdrawn your
            consent, we will no longer process your personal information for the
            purpose or purposes you originally agreed to, unless we have another
            legitimate basis for doing so
          </p>
          <p className="fs-2">
            What happens if you fail to provide us with your personal
            information?
          </p>
          <p>
            If you fail to provide certain information when requested, we may
            not be able to perform the contract we have entered into with you
            (such as providing you with our products or services)
          </p>
          <p className="fs-2">Time limit to respond</p>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated
          </p>
          <p className="fs-2">Updates to our Privacy Notice</p>
          <p>
            We may change, modify, add or remove portions of this privacy notice
            at any time, and any changes will become effective immediately. Any
            changes we make to our privacy notice will be posted on this page
            and, where appropriate, notified to you
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
