import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

const Header: React.FC = () => {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img src="/images/logo192.png" height="40px" alt="logo" />
          <img
            src="/images/company_name_short.png"
            height="40px"
            alt="Sun One Page Limited"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="menu-products" />
        <Navbar.Collapse id="menu-products">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Products" id="products">
              <NavDropdown.Item href="/products/bookkeeper">
                Bookkeeper
              </NavDropdown.Item>
              <NavDropdown.Item href="/products/crm">CRM</NavDropdown.Item>
              <NavDropdown.Item href="/products/inventory">
                Inventory
              </NavDropdown.Item>
              <NavDropdown.Item href="/products/rota">Rota</NavDropdown.Item>
              <NavDropdown.Item href="/products/ticketing">
                Ticketing
              </NavDropdown.Item>
              <NavDropdown.Item href="/products/workflow">
                Workflow
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Services" id="services">
              <NavDropdown.Item href="/services/workflow-api">
                Workflow API
              </NavDropdown.Item>
              <NavDropdown.Item href="/services/si">
                Solution Integration
              </NavDropdown.Item>
              <NavDropdown.Item href="/services/customize">
                Customization
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/privacy">Privacy</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
