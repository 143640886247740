import React from "react";
// import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from "components/ExampleCarouselImage";
import "./Banner.css";
import { Col, Container, Row } from "react-bootstrap";

interface BannerProps {
  index: number;
  setIndex: (idx: number) => void;
}

const Banner: React.FC<BannerProps> = ({ index, setIndex }) => {
  // const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        {/* <ExampleCarouselImage text="First slide" /> */}
        <div className="soil-bg">
          <Container>
            <Row>
              <Col xs={4} className="div-api">
                <span className="text-green font-api">APIs</span>
              </Col>
              <Col xs={8} className="div-customizations">
                <span className="text-red font-customizations">
                  Customizations
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={5} className="div-apps">
                <span className="text-blue font-apps">Apps</span>
              </Col>
              <Col xs={7} className="div-integrations">
                <span className="text-yellow font-integrations">
                  Automations
                </span>
              </Col>
            </Row>
          </Container>
        </div>
        <Carousel.Caption>
          <h3>A Startup for Startups</h3>
          <p>Let you focus on your business while we look after your techs</p>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <ExampleCarouselImage text="Second slide" />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ExampleCarouselImage text="Third slide" />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  );
};

export default Banner;
