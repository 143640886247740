import React from "react";
import { Card, ListGroup } from "react-bootstrap";

interface Link {
  url: string;
  text: string;
}

interface ProductCardProps {
  width?: string;
  imgPosition?: string;
  imgSrc?: string;
  title?: string;
  subTitle?: string;
  texts?: string[];
  links?: Link[];
}

const ProductCard: React.FC<ProductCardProps> = ({
  width,
  imgPosition,
  imgSrc,
  title,
  subTitle,
  texts,
  links,
}) => {
  return (
    <Card style={{ width: width || "100%" }}>
      {imgPosition && <Card.Img variant={imgPosition} src={imgSrc} />}
      {(title || subTitle || texts) && (
        <Card.Body>
          {title && <Card.Title>{title}</Card.Title>}
          {subTitle && (
            <Card.Subtitle className="mb-2 text-muted">
              {subTitle}
            </Card.Subtitle>
          )}
          {texts &&
            texts.length > 0 &&
            texts.map((t, idx) => <Card.Text key={idx}>{t}</Card.Text>)}
        </Card.Body>
      )}
      {!!links && links.length > 0 && (
        <Card.Body>
          <ListGroup variant="flush">
            {links.map((l, idx) => (
              <ListGroup.Item key={idx}>
                <Card.Link href={l.url}>{l.text}</Card.Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      )}
    </Card>
  );
};

export default ProductCard;
