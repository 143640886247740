import React from "react";
import "./Modal.css";
import { CloseButton } from "react-bootstrap";

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: any;
}

const Modal: React.FC<ModalProps> = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal"
      tabIndex={-1}
      role="dialog"
      style={{
        display: "block",
        backdropFilter: "blur(15px)",
        paddingTop: "120px",
      }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header title-bg">
            <h5 className="modal-title">{title || "Notice"}</h5>
            <CloseButton aria-label="Close" onClick={onClose} />
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
